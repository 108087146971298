import {
  Box,
  Button, capitalize,
  Card,
  CardActions, Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  ThemeProvider, Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import DialogActions from "@mui/material/DialogActions";
import {Add, Delete, DeleteForever, RotateLeft} from '@mui/icons-material';
import {firestore} from "../../../firebase/clientApp";
import {useParams} from "react-router-dom";
import {pleshDark} from "../../../theme/pleshDark";
import CustomConfirmDialog from "../../shared/CustomConfirmDialog";

const AgendaSetup = ({event, closeSettings, handleSetSuccess, handleSetSaving}) => {
  const {id} = useParams()
  const [agendaState, setAgendaState] = React.useState({
    agenda: event.data().agenda,
    contenutoAgenda: event.data().contenutoAgenda
  })

  const handleUpdateAgenda = async () => {
    handleSetSaving(true)
    await firestore.doc(`users/plesh/events/${id}`).update({
      agenda: agendaState.agenda,
      contenutoAgenda: agendaState.contenutoAgenda || [],
    })
      .then(() => handleSetSuccess(true))
      .finally(() => handleSetSaving(false))
  }

  return (
    <Box sx={{width: '100%'}}>
      <Stack mb={1} direction={'row'} alignItems={'center'}>
        <Box>
          <Checkbox checked={agendaState.agenda}
                    onChange={(e) =>
                      setAgendaState({...agendaState, agenda: e.target.checked})}
                    sx={{
                      color: pleshDark.palette.primary.main,
                    }}
          />
        </Box>
        <Typography variant={"h6"} color={pleshDark.palette.text.primary}>Show Agenda</Typography>
      </Stack>
      <Stack sx={{minHeight: '15rem'}}>
        <AgendaSettings agendaState={agendaState} setAgendaState={setAgendaState}/>
      </Stack>
      <Divider sx={{paddingTop: 2}}/>
      <DialogActions>
        <Button onClick={() => closeSettings()}>Close</Button>
        <Button variant={"contained"} onClick={handleUpdateAgenda}>Save</Button>
      </DialogActions>
    </Box>
  );
}

export default AgendaSetup;

const AgendaSettings = ({agendaState, setAgendaState}) => {
  const [days, setDays] = useState(agendaState.contenutoAgenda || [
    {
      id: 1,
      date: '',
      title: '',
      timeSlots: [{id: 1, startTime: '', endTime: '', description: ''}]
    }
  ]);

  const addDay = () => {
    const newDay = {
      id: days.length + 1,
      date: '',
      title: '',
      timeSlots: [{id: 1, startTime: '', endTime: '', description: ''}]
    };
    setDays([...days, newDay]);
  };

  const removeDay = (dayId) => {
    setDays(days.filter(day => day.id !== dayId));
  };

  const updateDay = (dayId, field, value) => {
    setDays(days.map(day =>
      day.id === dayId ? {...day, [field]: value} : day
    ));
  };

  const addTimeSlot = (dayId) => {
    setDays(days.map(day => {
      if (day.id === dayId) {
        const newTimeSlotId = day.timeSlots.length + 1;
        return {
          ...day,
          timeSlots: [
            ...day.timeSlots,
            {id: newTimeSlotId, startTime: '', endTime: '', description: ''}
          ]
        };
      }
      return day;
    }));
  };

  const removeTimeSlot = (dayId, timeSlotId) => {
    setDays(days.map(day => {
      if (day.id === dayId) {
        return {
          ...day,
          timeSlots: day.timeSlots.filter(slot => slot.id !== timeSlotId)
        };
      }
      return day;
    }));
  };

  const updateTimeSlot = (dayId, timeSlotId, field, value) => {
    setDays(days.map(day => {
      if (day.id === dayId) {
        return {
          ...day,
          timeSlots: day.timeSlots.map(slot =>
            slot.id === timeSlotId ? {...slot, [field]: value} : slot
          )
        };
      }
      return day;
    }));
  };

  useEffect(() => {
    setAgendaState({...agendaState, contenutoAgenda: days})
  }, [days])

  return (
    <Stack width={'100%'}>
      {days.map((day) => (
        <Card key={day.id} elevation={3} sx={{p: 2, pt: 0, mb: 2}}>
          <DialogActions>
            <Button
              startIcon={<Delete/>}
              color="error"
              onClick={() => removeDay(day.id)}
              disabled={days.length <= 1}
            >
              Delete day
            </Button>
          </DialogActions>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                size="small"
                fullWidth
                label="Date"
                type="date"
                value={day.date}
                onChange={(e) => updateDay(day.id, 'date', e.target.value)}
                InputLabelProps={{shrink: true}}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                size="small"
                fullWidth
                label="Title of the day"
                value={day.title}
                onChange={(e) => updateDay(day.id, 'title', e.target.value)}
              />
            </Grid>
            {/*<Grid item xs={12} md={2}>
                            <IconButton
                                color="error"
                                onClick={() => removeDay(day.id)}
                                disabled={days.length <= 1}
                            >
                                <Delete/>
                            </IconButton>
                        </Grid>*/}
          </Grid>

          {day.timeSlots.map((timeSlot) => (
            <Box key={timeSlot.id} sx={{mb: 4, mt: 2}}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={5} sm={3}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Starting Time"
                    type="time"
                    value={timeSlot.startTime}
                    onChange={(e) => updateTimeSlot(day.id, timeSlot.id, 'startTime', e.target.value)}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>
                <Grid item xs={5} sm={3}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Ending Time"
                    type="time"
                    value={timeSlot.endTime}
                    onChange={(e) => updateTimeSlot(day.id, timeSlot.id, 'endTime', e.target.value)}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>
                <Grid item xs={2} sm={1}>
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => removeTimeSlot(day.id, timeSlot.id)}
                    disabled={day.timeSlots.length <= 1}
                  >
                    <DeleteForever fontSize={'small'}/>
                  </IconButton>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Description"
                    value={timeSlot.description}
                    onChange={(e) => updateTimeSlot(day.id, timeSlot.id, 'description', e.target.value)}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}

          <Box sx={{mt: 2}}>
            <Button
              size="small"
              startIcon={<Add/>}
              variant="outlined"
              onClick={() => addTimeSlot(day.id)}
            >
              Add Time Slot
            </Button>
          </Box>
        </Card>
      ))}
      <Box sx={{mt: 2, display: 'flex', justifyContent: 'space-between'}}>
        <Button
          startIcon={<Add/>}
          variant="contained"
          onClick={addDay}
        >
          Add Day
        </Button>
      </Box>
    </Stack>
  );
};