import React, {useEffect, useState} from "react";
import {
  Accordion, AccordionDetails, AccordionSummary,
  Box,
  Button,
  capitalize,
  Card,
  Checkbox,
  Stack,
  TextareaAutosize,
  TextField,
  Typography
} from "@mui/material";
import {firestore} from "../../../firebase/clientApp";
import {FileUploader} from "react-drag-drop-files";
import axios from "axios";
import {useParams} from "react-router-dom";
import {ArrowDownward, ArrowUpward, Storage, ExpandMore, RotateLeft, Upload} from "@mui/icons-material";
import {API_URL} from "../../../config";
import {TeamsSetup} from "./TeamsSetup";
import CustomConfirmDialog from "../../shared/CustomConfirmDialog";
import DialogActions from "@mui/material/DialogActions";
import {pleshDark} from "../../../theme/pleshDark";
import {cloneDeep} from "lodash";

const fileTypes = ["JPEG", "PNG", "JPG"];

export const HomeSetup = ({event, teams, closeSettings, handleSetSaving, handleSetSuccess}) => {
  const {userId, id} = useParams()
  const [home, setHome] = useState({})
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState('')
  const [reactions, setReactions] = useState([1,2,3,4])
  const handleChangeLogo = (file) => {
    setLogo(file);
    setLogoUrl(URL.createObjectURL(file))
  };

  useEffect(() => {
    setHome({
      autenticazione: event.data().autenticazione,
      squadre: event.data().squadre,
      applausometro: event.data().applausometro,
      agenda: event.data().agenda,
      logo: event.data().logo
    })
    setLogoUrl(event.data().logoUrl)
    setReactions(event.data()?.reactions || [1,2,3,4])
  }, [event])

  const handleSetHome = (e, field) => {
    setHome({...home, [field]: e.target.checked})
  }

  function handleReactionChange(event, index) {
    console.log(index, event.target.value)
    //home.reactions[index] = event.target.value;
    //setHome({...home, reactions: home.reactions});
    const _reactions = cloneDeep(reactions)
    _reactions[index] = event.target.value;
    setReactions(_reactions);
  }

  const saveSettings = async (e) => {
    handleSetSaving(true)
    const formData = new FormData();
    formData.append('file', logo);
    if (logo !== null) {
      await axios({
        url: `${API_URL}/users/${userId}/events/${event.id}/logo`,
        method: 'POST',
        data: formData,
      }).then()
    }
    await firestore.doc(`users/${userId}/events/${event.id}`).update({
      autenticazione: home.autenticazione,
      applausometro: home.applausometro,
      reactions: reactions,
      agenda: home.agenda,
      logo: home.logo,
      squadre: home.squadre || false
    }).then(handleSetSuccess(true))
    handleSetSaving(false)

  }

  const handleResetApplausi = async (e) => {
    handleSetSaving(true)

    if (home.squadre) {
      for (let i = 0; i < teams.size; i++) {
        await axios.post(`${API_URL}/users/${userId}/${id}/${teams.docs[i].data()?.label}/reset`)
      }
    }

    for (let i = 0; i < reactions.length; i++) {
      await axios.post(`${API_URL}/users/${userId}/${id}/${i+1}/reset`)
    }

    handleSetSaving(false)
  }

  return (
    <Box sx={{width: '100%'}}>
      <Typography variant={"h5"}>Home</Typography>
      {Object.keys(home).map(o =>
        <Box my={1} sx={{width: '100%', p: 0.5}} key={o} component={Card}>
          <Stack direction={'row'} alignItems={'center'}>
            <Box>
              <Checkbox checked={home[o]} onChange={(e) => handleSetHome(e, o)}/>
            </Box>
            <Typography variant={"h6"}>{capitalize(o)}</Typography>
            {
              o === 'applausometro' &&
              <Box marginLeft={'auto'} px={1}>
                <CustomConfirmDialog icon={<RotateLeft/>}
                                     confirmAction={handleResetApplausi}
                                     label={"Reset applause"}
                                     confirmText={<>Sei sicuro? {home.squadre ?
                                       <i><br/>Applause from individual teams will also be eliminated.</i> : ""}</>}
                                     size={'small'}
                />
              </Box>
            }
          </Stack>
          {o === 'applausometro' &&
            home.applausometro &&
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore/>}>
                <Stack width={'100%'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography>Reactions settings</Typography>
                  <Button startIcon={<Storage/>}
                          href={"https://console.cloud.google.com/storage/browser/plesh-bucket-public/reactions-2ndStage?pageState=(%22StorageObjectListTable%22:(%22f%22:%22%255B%255D%22))&inv=1&invt=AbnnCQ&project=dev-cloud-plesh"}
                          target={"_blank"}
                          onClick={(event) => event.stopPropagation()}
                          style={{color: pleshDark.palette.primary.main}}
                  >
                    Bucket GCP
                  </Button>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Box px={2}>
                  {[1, 2, 3, 4].map((index) => (
                    <Stack key={index} direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={2}>
                      <Box p={1} border={'1px solid ' + pleshDark.palette.primary.main} borderRadius={'0.5rem'}>
                        <a href={`https://storage.googleapis.com/plesh-bucket-public/reactions-2ndStage/${id}/${index}.png`} target="_blank" rel="noopener noreferrer">
                          <img
                            src={`https://storage.googleapis.com/plesh-bucket-public/reactions-2ndStage/${id}/${index}.png`}
                            style={{width: '30px', height: '30px'}}
                          />
                        </a>
                      </Box>
                      <TextField
                        size="small"
                        margin="dense"
                        id={"emoji" + index}
                        label={":)"}
                        type="text"
                        fullWidth
                        variant="standard"
                        helperText={`Emoji ${index}`}
                        value={reactions[index-1]}
                        onChange={(event) => handleReactionChange(event, index-1)}
                      />
                    </Stack>
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>}
          {o === 'logo' &&
            home.logo &&
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore/>}>
                Logo settings
              </AccordionSummary>
              <AccordionDetails>
                <Box px={2}>
                  <Typography variant={"body2"} sx={{mb: 1}}>Logo:</Typography>
                  <FileUploader
                    multiple={false}
                    handleChange={handleChangeLogo}
                    name="file"
                    types={fileTypes}
                    children={

                      <Box sx={{
                        width: '100%',
                        maxWidth: '20rem',
                        p: 2,
                        border: '2px dashed white',
                        borderRadius: '1rem',
                        textAlign: 'center'
                      }}>
                        {logoUrl !== '' ?
                          <Box>
                            <img src={logoUrl} style={{width: '100%'}}/>
                            <Typography variant={"body2"} sx={{mb: 1}}>Clicca qui per sostituire il
                              tuo logo</Typography>
                            <Upload sx={{fontSize: '3rem'}}/>
                          </Box>
                          :
                          <Box>
                            <Typography variant={"body2"} sx={{mb: 1}}>Clicca qui per caricare il
                              tuo logo</Typography>
                            <Upload sx={{fontSize: '3rem'}}/>
                          </Box>
                        }
                      </Box>
                    }
                  />
                </Box>
              </AccordionDetails>
            </Accordion>}
          {o === 'squadre' && home.squadre &&
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore/>}>
                Teams settings
              </AccordionSummary>
              <AccordionDetails>
                <Box px={2}>
                  <TeamsSetup teams={teams} closeSettings={closeSettings} handleSetSaving={handleSetSaving}
                              handleSetSuccess={handleSetSuccess}/>
                </Box>
              </AccordionDetails>
            </Accordion>}
        </Box>
      )}
      <DialogActions>
        <Button onClick={() => closeSettings()}>Close</Button>
        <Button variant={"contained"} onClick={saveSettings}>Save</Button>
      </DialogActions>
    </Box>
  )
}
