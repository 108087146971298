import React from "react";
import {Box, Chip} from "@mui/material";
import {
    Cloud,
    Flaky,
    HorizontalRule,
    InsertEmoticon, Link,
    Mic,
    PanTool,
    Photo,
    Poll,
    QuestionAnswer,
    QuestionMark,
    Quiz,
    TouchApp,
    FormatListNumbered, TipsAndUpdates, WbIridescent, WbIncandescent, Rtt
} from "@mui/icons-material";
import {pleshDark} from "../theme/pleshDark";

export default function QueuePin({tipo, color}) {
    return (
        <Box>
            <Chip sx={{minWidth:'8rem',
                background: color || pleshDark.palette.primary.main,
                color: pleshDark.palette.getContrastText(color || pleshDark.palette.primary.main)
            }}
                  //color={color || 'primary'}
                  //sx={{background: color || 'primary'}}
                  icon={questionTypes[tipo]?.icon}
                  label={questionTypes[tipo]?.text ?? tipo}/>
        </Box>
    )
}

export const questionTypes = {
    /*qna: {
        text: 'Q&A',
        icon: <QuestionAnswer/>
    },*/
    quiz: {
        id: 'quiz',
        text: 'Quiz',
        icon: <Quiz/>
    },
    poll: {
        id: 'poll',
        text: 'Poll',
        icon: <Poll/>
    },
    multiPoll: {
        id: 'multiPoll',
        text: 'Multi Poll',
        icon: <Poll/>
    },
    reorder: {
        id: 'reorder',
        text: 'Reorder question',
        icon: <FormatListNumbered/>
    },
    wordcloud: {
        id: 'wordcloud',
        text: 'Wordcloud',
        icon: <Cloud/>
    },
    sendPhoto: {
        id: "sendPhoto",
        text: "Send photo",
        icon: <Photo/>
    },
    toolpress: {
        id: 'toolpress',
        text: 'Toolpress',
        icon: <QuestionAnswer/>
    },
    reserveSpot: {
        id: 'reserveSpot',
        text: "Reserve Spot!",
        icon: <PanTool/>
    },
    audio: {
        id: 'audio',
        text: "Send audio",
        icon: <Mic/>
    },
    siNo: {
        id: 'siNo',
        text: 'Yes / No',
        icon: <Flaky/>
    },
    sentiment: {
        id: 'sentiment',
        text: 'Sentiment',
        icon: <InsertEmoticon/>
    },
    links: {
        id: 'links',
        text: 'Link',
        icon: <Link/>
    },
    text: {
        id: 'text',
        text: 'Text',
        icon: <Rtt/>
    },
    color: {
        id: 'color',
        text: 'Color smartphones',
        icon: <WbIncandescent/>
    },
    clickTo: {
        id: 'clickTo',
        text: 'Click To',
        icon: <TouchApp/>
    },
    divider: {
        id: 'divider',
        text: "Divider",
        icon: <HorizontalRule/>
    },
    altro: {
        id: 'altro',
        text: "Other",
        icon: <QuestionMark/>
    },
}
