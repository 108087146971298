import React from "react";
import {Box, ListItemIcon, ListItemText, MenuItem, Stack} from "@mui/material";
import {EmojiEmotions, Equalizer, Groups, MilitaryTech, ThumbUp} from "@mui/icons-material";
import {Link} from "react-router-dom";

export const PagesButtons = ({userId, id, event}) => {
  const buttons = [
    {
      label: 'Active question graph',
      href: `/${userId}/control/${id}/domanda`,
      icon: <Equalizer/>
    },
    {
      label: 'Leaderboard',
      href: `/${userId}/results/${id}`,
      icon: <MilitaryTech/>,
      condition: 'autenticazione'
    },
    {
      label: 'Teams Ranking',
      href: `/${userId}/team-results/${id}`,
      icon: <Box mr={2}><MilitaryTech/><Groups/></Box>,
      condition: 'squadre'
    },
    {
      label: 'Applause graph',
      href: `/${userId}/control/${id}/grafici`,
      icon: <ThumbUp/>
    },
    {
      label: 'Applause number',
      href: `/${userId}/control/${id}/applausi`,
      icon: <ThumbUp/>
    },
    {
      label: 'Reactions Live',
      href: `/${userId}/control/${id}/reactions`,
      icon: <EmojiEmotions/>
    }
  ]

  return (
    <Stack direction={'column'} justifyContent={"flex-start"} spacing={1}>
      {buttons.map((button, index) =>
        <MenuItem //disabled={button?.condition && event[button?.condition] !== true}
          key={index} variant={"outlined"}
          component={Link}
          to={button.href}
          target={'_blank'}>
          <ListItemIcon>{button.icon}</ListItemIcon>
          <ListItemText>{button.label}</ListItemText>
        </MenuItem>
      )}
    </Stack>
  )
}
