import React from 'react'
import {TextField} from "@mui/material";
import QuizForm from "./FormDomande/QuizForm";
import {ClickToForm} from "./FormDomande/ClickToForm";
import Box from "@mui/material/Box";
import {ToolpressForm} from "./FormDomande/ToolpressForm";
import LinkForm from "./FormDomande/LinkForm";
import SendPhotoForm from "./FormDomande/SendPhotoForm";
import ColorForm from "./FormDomande/ColorForm";
import * as PropTypes from "prop-types";
import TextForm from "./FormDomande/TextForm/TextForm";

export default function AddQueueElementForm({
                                              type,
                                              handleSetDomanda,
                                              handleSetColore,
                                              handleSetTransitionColor,
                                              handleSetRisposte,
                                              risposte,
                                              handleSetCorretta,
                                              corretta,
                                              hideResult,
                                              handleHideResult,
                                              colore,
                                              colorTransitionDuration,
                                              colorLoop,
                                              handleSetColorLoop,
                                              handleSetConferenza,
                                              handleSetTextContent,
                                              conferenza,
                                              textContent
}) {

    function setDomanda(event) {
        handleSetDomanda(event.target.value)
    }

    return(
        <Box>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Question text"
                type="text"
                fullWidth
                required
                variant="standard"
                onChange={setDomanda}
            />
            {(() => {
                switch (type) {
                    //case 'color':
                    case 'clickTo':
                    case 'divider':
                        return <ClickToForm handleSetColore={handleSetColore} colore={colore}/>
                    case 'color':
                        return <ColorForm handleSetColori={handleSetRisposte} colori={risposte}
                                          colorTransitionDuration={colorTransitionDuration}
                                          handleSetTransitionColor={handleSetTransitionColor}
                                          colorLoop={colorLoop}
                                          handleSetColorLoop={handleSetColorLoop}/>
                    case 'links':
                        return <LinkForm handleSetLinks={handleSetRisposte} links={risposte} />
                    case 'text':
                        return <TextForm handleSetContent={handleSetTextContent} content={textContent} />
                    case 'quiz':
                        return <QuizForm handleSetRisposte={handleSetRisposte} risp={risposte} handleSetCorretta={handleSetCorretta} corr={corretta} hideResult={hideResult} handleHideResult={handleHideResult}/>
                    case 'sendPhoto':
                        return <SendPhotoForm hideResult={hideResult} handleHideResult={handleHideResult}/>
                    case 'poll':
                    case 'multiPoll':
                    case 'reorder':
                        return <QuizForm handleSetRisposte={handleSetRisposte} risp={risposte} poll={true}/>
                    case 'toolpress':
                        return <ToolpressForm handleSetConferenza={handleSetConferenza} conferenza={conferenza}/>
                    default:
                        return null
                }
            })()}
        </Box>
    )
}
