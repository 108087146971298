import {Button, CircularProgress} from "@mui/material";
import {AutoAwesome} from "@mui/icons-material";
import React, {useState} from "react";
import axios from "axios";
import {API_URL} from "../../config";
import {downloadImage} from "../Control/DomandeVisual/SendPhotoVisual";
import {useParams} from "react-router-dom";

const WordStormGenerator = ({questionId = null}) => {
  const {userId, id, domanda} = useParams()
  const [loading, setLoading] = useState(false)

  const handleGenerateWordcloud = () => {
    setLoading(true)
    axios.post(`${API_URL}/users/${userId}/answer/${id}/${questionId || domanda}/wordcloud/generate-wordstorm`, {})
      .then(response => {
        console.log("Wordstorm", response)
        downloadImage(response.data, "wordstorm")
      })
      .catch(error => console.log("error banning", error))
      .finally(() => setLoading(false))
  }

  return (
    <Button
      variant="contained"
      color="primary"
      sx={{width: '100%'}}
      onClick={handleGenerateWordcloud}
      startIcon={loading ?
        <CircularProgress size={24} color="inherit"/>
        : <AutoAwesome/>}
    >
      {"AI WordStorm"}
    </Button>
  )
}

export default WordStormGenerator