import React, {useState} from "react";
import {Button, Card, IconButton, Stack} from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import QrButton from "./QrButton";
import RemoveEventButton from "./RemoveEventButton";
import {Archive, Edit, Stop, Unarchive} from "@mui/icons-material";
import {firestore} from "../firebase/clientApp";
import EditEvent from "./EditEvent";
import {useParams} from "react-router-dom";

function Event({event, handleRemoveEvent}) {
    const {userId} = useParams()
    const [open, setOpen]=useState(false)

    function pauseEvent() {
        firestore
            .collection(`users/${userId}/events`)
            .doc(event.id)
            .set(
                {playing: !event.playing},
                {merge: true}
            ).then(r => console.log('Updated'))
    }

    const handleSetOpen = () => {
        let o = !open
        setOpen(o)
    }

    const archiveEvent = () => {
        firestore
            .collection(`users/${userId}/events`)
            .doc(event.id)
            .update({archived: !event.archived})
            .then()
    }

    return (
        <Card sx={{m: 2}}>
            <Stack sx={{p: 1}} direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"}>
                    <Button href={`/${userId}/events/${event.id}`} size={"large"}>{event.title}</Button>
                    {
                        event.playing ? '' :
                            <IconButton size={"large"} onClick={handleSetOpen}>
                                <Edit fontSize={"inherit"}/>
                            </IconButton>
                    }
                </Stack>
                <Stack direction={"row"}>
                    <IconButton aria-label="play" size={"large"} onClick={archiveEvent}>
                        {event.archived ?
                            <Unarchive fontSize={"inherit"}/>
                            : <Archive fontSize={"inherit"}/>}
                    </IconButton>
                    <RemoveEventButton event={event} handleRemoveEvent={handleRemoveEvent}/>
                    <QrButton event={event}/>
                    <IconButton aria-label="play" size={"large"} onClick={pauseEvent}>
                        {
                            event.playing ?
                                <Stop fontSize={"inherit"}/>
                                : <PlayArrowIcon fontSize={"inherit"}/>
                        }
                    </IconButton>
                </Stack>
            </Stack>
            <EditEvent event={event} handleSetOpen={handleSetOpen} open={open}/>
        </Card>
    )

}

export default Event
