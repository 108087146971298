import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {db} from "../firebase/clientApp";

const Dati = () => {
    const {userId, id} = useParams()
    const [answers, setAnswers] = useState({})

    useEffect(() => {
        getAnswers()
    }, [])

    const getAnswers = () => {
        db.ref(`/users/${userId}/events/${id}`).on('value', (s) => setAnswers(s.val()))
    }

    return(
        <div>Dati</div>
    )
}

export default Dati
