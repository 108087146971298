import React from "react";
import {Box, Typography} from "@mui/material";
import {CustomColorPicker} from "../shared/CustomColorPicker";

export const ClickToForm = ({handleSetColore, colore}) => {
    return(
        <Box mt={1}>
            <Typography gutterBottom>Choose a color</Typography>
            <CustomColorPicker color={colore} handleUpdateColor={handleSetColore}/>
            {/*<TextField
                autoFocus
                margin="dense"
                id="name"
                label="Colore"
                type="text"
                fullWidth
                variant="standard"
                value={colore}
                onChange={(e) => handleSetColore(e.target.value)}
            />*/}
        </Box>
    )
}
