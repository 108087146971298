import React from "react";
import {Button, ListItemIcon, ListItemText, MenuItem, Slide} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import {DeleteForever} from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DeleteDialog(props) {
    const {onClose, open, handleRemove} = props;

    const handleClose = () => {
        onClose();
    };

    const remove = () => {
        handleRemove()
        handleClose()
    }

    return (
        <Dialog onClose={handleClose} open={open} TransitionComponent={Transition}>
            <DialogTitle>Are you sure?</DialogTitle>
            <Button onClick={handleClose}>
                No
            </Button>
            <Button onClick={remove}>
                Yes
            </Button>
        </Dialog>
    );
}

DeleteDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default function RemoveQueueElementButton({id, handleRemoveElement}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleRemove() {
        handleClose()
        handleRemoveElement(id)
    }

    return (
        <MenuItem onClick={handleClickOpen}>
            <ListItemIcon>
                    <DeleteForever/>
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
            <DeleteDialog
                handleRemove={handleRemove}
                open={open}
                onClose={handleClose}
            />
        </MenuItem>
    )
}
