import {alpha, styled} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {pleshDark} from "../../../theme/pleshDark";

const primary = pleshDark.palette.primary.main
const primaryAlpha = alpha(pleshDark.palette.primary.main, 0.7)

const StyledQuill = styled(ReactQuill)`
    /* Modifica il border-radius del container della toolbar */
    .ql-toolbar {
        border-radius: 10px 10px 0 0 !important; /* Cambia il valore come preferisci */
        background-color: ${pleshDark.palette.background.default} !important; /* Cambia lo sfondo della toolbar */
        border: 0px solid #666 !important; /* Bordo della toolbar */
    }

    .ql-editor {
        border-radius:  0 0 10px 10px !important; /* Cambia il valore come preferisci */
        background-color: ${pleshDark.palette.background.paper} !important; /* Cambia lo sfondo della toolbar */
        border: 0px solid #666 !important; /* Bordo della toolbar */
    }
    
    .ql-container {
        border: 0 !important; /* Bordo del container */
    }
    
    /* STATO NORMALE (non selezionato, colore base) */

    .ql-toolbar button,
    .ql-toolbar .ql-picker-label,
    .ql-toolbar .ql-picker-item,
    .ql-toolbar .ql-stroke {
        color: #b3b3b3 !important; /* Grigio scuro */
        stroke: #b3b3b3 !important; /* Per le icone SVG */
    }

    /* STATO HOVER (quando passi sopra con il mouse) */

    .ql-toolbar button:hover,
    .ql-toolbar .ql-picker-label:hover,
    .ql-toolbar .ql-picker-item:hover,
    .ql-toolbar button:hover .ql-stroke {
        color: ${primaryAlpha} !important; /* Arancione */
        stroke: ${primaryAlpha} !important;
    }

    /* STATO ATTIVO (quando il pulsante è selezionato) */

    .ql-toolbar button.ql-active,
    .ql-toolbar .ql-picker-label.ql-active,
    .ql-toolbar .ql-picker-item.ql-selected,
    .ql-toolbar button.ql-active .ql-stroke {
        color: ${primary} !important; /* Rosso */
        stroke: ${primary} !important;
    }

    /* Dropdown selezionato */

    .ql-toolbar .ql-picker-item.ql-selected {
        font-weight: bold !important;
        color: ${primary} !important;
    }

    /* 2️⃣ Modifica lo sfondo e il bordo dei dropdown */
    .ql-toolbar .ql-picker-options {
        background-color: #222 !important; /* Sfondo del menu a tendina */
        border: 1px solid #555 !important; /* Bordo del menu */
        border-radius: 8px !important; /* Smussatura angoli */
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3) !important; /* Ombra */
    }

    /* 3️⃣ Cambia il colore delle opzioni nel dropdown */
    .ql-toolbar .ql-picker-item {
        color: #ddd !important; /* Testo opzioni dropdown */
    }

    /* 4️⃣ Cambia il colore di hover delle opzioni nel dropdown */
    .ql-toolbar .ql-picker-item:hover {
        background-color: ${primaryAlpha} !important; /* Sfondo arancione al passaggio */
        color: white !important; /* Testo bianco */
    }

    /* 5️⃣ Cambia il colore della voce selezionata nel dropdown */
    .ql-toolbar .ql-picker-item.ql-selected {
        background-color: ${primary} !important; /* Rosso */
        color: white !important;
        font-weight: bold !important;
    }
`;

const CustomQuillTextEditor = ({value, setValue}) => {
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ font: [] }],
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ align: [] }], // Pulsanti di allineamento
      [{ list: "ordered" }, { list: "bullet" }, { list: 'check' }], // Liste
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      ["link", "image", /*'video', 'formula'*/], // Link e immagini
      ["clean"], // Rimuove formattazione
    ],
  };

  return <StyledQuill theme="snow"
                      modules={modules}
                      value={value}
                      onChange={(_value, ) => {
                        console.log(_value)
                        setValue(_value);
                      }}
  />;
};

export default CustomQuillTextEditor;
