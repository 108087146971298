import React, {useEffect, useState} from "react";
import {Box, Button, Chip, Container, Stack, TextField, Typography} from "@mui/material";
import axios from "axios";
import {API_URL} from "../config";
import {useParams} from "react-router-dom";
import {ClearAll, FormatColorReset, Groups, ResetTv} from '@mui/icons-material';
import {useQuery} from "../custom-hooks/useQuery";
import {orderBy} from "lodash";
import CustomConfirmDialog from "../components/shared/CustomConfirmDialog";

export const RankResults = ({team}) => {
    const {userId, id} = useParams()
    const timer = 2000

    const query = useQuery()
    const control = query.get('control') === 'true'

    const [extraPoints, setExtraPoints] = useState('20')
    const [results, setResults] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState(false)

    /*useEffect(() => {
        getResults()
    }, [])*/

    useEffect(() => {
        getResults()
        window.clearInterval()
        let intervalId = window.setInterval(() => getResults(), timer);
        return () => {
            window.clearInterval(intervalId)
        }
    }, [])

    const handleAddPoints = (event) => {
        axios.put(`${API_URL}/events/${id}/users/${event.target.id}/points`, {
            incrementPoints: extraPoints
        })
            .then(response => console.log("boosted", response))
            .catch(error => console.log("error boosting", error))
    }

    const getResults = async () => {
        //setLoading(true)
      await axios.get(!!team ? `${API_URL}/team-result/${userId}/${id}` : `${API_URL}/result/${userId}/${id}`)
            .then(r => {
                setResults(orderBy(
                    r.data.filter((r) => {
                        return !!r.name || (team && !!r.teamName)
                    }),
                ['points', 'createdAt._seconds'], ['desc', 'asc']))
            })
            .catch(e => {
                setError(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    //if (loading) return (<Box>Loading...</Box>)

    const handleResetRank = async () => {
        setLoading(true)
        await axios.delete(`${API_URL}/events/${id}/users`)
            .then(r => {
                console.log(r)
            })
            .catch(e => {
                console.log(e)
                setError(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Container sx={{py: 2}}>
            {control &&
                <CustomConfirmDialog
                    confirmAction={handleResetRank}
                    disabled={results.length === 0}
                    icon={<ClearAll/>}
                    label={"Reset classifica"}
                />}
            {results.map((r, i) =>
                <Box key={i} sx={{py:1}}>
                    <Stack direction={'row'} alignItems={'center'} spacing={4}>
                        <Box sx={{width:'3rem'}}>
                            <Typography variant={'h4'}>
                                {++i}{/*r.position*/}
                            </Typography>
                        </Box>
                        <Box width={'100%'}>
                                 <Stack direction={'row'} spacing={1}>
                                    {!team && <Typography variant={'h5'}>
                                        {r.name}
                                    </Typography>}
                                     {r.teamName &&
                                         <Chip sx={{px: 1, fontSize: team ? '1.25rem' : '0.8125rem'}}
                                               label={`TEAM ${r.teamName}`} icon={<Groups/>}/>}
                                </Stack>
                            <Typography>
                                {r.points}
                            </Typography>
                        </Box>
                        {
                            control && !team &&
                                <Stack direction={'row'} alignItems={'center'} spacing={1} width={'100%'}>
                                    <TextField
                                        type={'number'}
                                        defaultValue={20}
                                        value={extraPoints}
                                        onChange={(event) => {
                                            setExtraPoints(event.target.value);
                                        }}
                                    >

                                    </TextField>
                                    <Button id={r.id} onClick={handleAddPoints} disabled={extraPoints === '0'}>
                                        Add points ({extraPoints})
                                    </Button>
                                </Stack>
                        }
                    </Stack>
                </Box>
            )}
        </Container>
    )
}