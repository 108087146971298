import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Button, CircularProgress, Grid, IconButton, Stack} from "@mui/material";
import {useParams} from "react-router-dom";
import {useQuery} from "../../../custom-hooks/useQuery";
import {AllInclusive, Download, Recommend, ThumbDown, ThumbUp} from "@mui/icons-material";
import {green, red} from "@mui/material/colors";
import axios from "axios";
import {API_URL, BUCKET} from "../../../config";
import {orderBy} from "lodash";
import JSZip from "jszip";
import {saveAs} from "file-saver";

export const SendPhotoVisual = ({result, getData, domanda, luma}) => {
  const {userId, id} = useParams()
  const query = useQuery()
  const [photos, setPhotos] = useState([])
  const columns = query.get('columns') ?? 12
  const spacing = query.get('spacing') ?? 8
  const order = query.get('order') ?? 'desc'
  const demo = query.get('demo') === 'true'

  useEffect(() => {
    if (result.data && result.data.length > 0) {
      if (demo) {
        let p = []
        for (let i = 0; i < 121; i++) {
          p.push({link: 'https://placehold.it/500x500'})
        }
        setPhotos(p)
      } else {
        let p = result.data
        p = orderBy(p, 'createdAt', query.get('control') === 'true' ? 'asc' : order)
        /*p.sort(function(a, b) {
            return b.createdAt - a.createdAt;
        });*/
        query.get('control') === 'true' ?
          setPhotos(p)
          :
          setPhotos(p.filter(p => p.approved).slice(0, 132))
      }
    }
  }, [result])

    const approvePhoto = async (photo) => {
        await axios
            .post(`${API_URL}/users/${userId}/answer/${id}/${domanda}/photo/${photo.id}/${photo.approved ? 'block' : 'approve'}`)
            .then(r => {getData()})
    }

  return (
    <Stack>
      {query.get('control') === 'true' &&
        <Stack mt={1} mr={1} direction='row' justifyContent={'flex-end'} alignItems={'flex-end'} spacing={1}>
          <DownloadAllPhotosButton filenames={photos.map((photo) => (`${BUCKET}/${userId === "plesh" ? '' : (userId+'/')}${id}/${domanda}/${photo.filename}`))}/>
          <DownloadAllPhotosButton onlyApproved filenames={photos.map((photo) => {
            if (photo.approved) return `${BUCKET}/${userId === "plesh" ? '' : (userId+'/')}${id}/${domanda}/${photo.filename}`
          })}/>
        </Stack>}
      <Grid container columns={columns}>
        {photos.map((p, i) =>
          <Grid item xs={1} key={i} sx={{marginBottom: '-6px'}}>
            <Box sx={{
              width: '100%', background: luma ? 'white'
                : 'auto', padding: spacing + 'px'
            }}
                 className={'photo-container'}>
              {query.get('control') === 'true' &&
                <Box className={'photo-commands'}>
                  <Stack alignItems={'center'}>
                    <IconButton onClick={() => approvePhoto(p)}
                                sx={{background: p.approved ? `${red[500]} !important` : `${green[500]} !important`}}>
                      {p.approved ? <ThumbDown/> : <ThumbUp/>}
                    </IconButton>
                    {query.get('control') === 'true' &&
                      <IconButton sx={{padding: 0.5, margin: 'auto', marginBottom: '8px',}}
                                  onClick={() => downloadImage(`${BUCKET}/${userId === "plesh" ? '' : (userId+'/')}${id}/${domanda}/${p.filename}`, p.filename)}>
                        <Download fontSize={'small'}/>
                      </IconButton>}
                  </Stack>
                </Box>}
              {!luma && <img style={{
                border: query.get('control') === 'true' ?
                  p.approved ? `5px solid ${green[500]}` : `5px solid ${red[500]}` : 'none',
                borderRadius: query.get('control') === 'true' ?
                  '8px' : 'none'
              }}
                             className={'photo'}
                             src={demo ? p.link : `${BUCKET}/${userId === "plesh" ? '' : (userId+'/')}${id}/${domanda}/${p.filename}`}/>}
            </Box>
          </Grid>
        )}
      </Grid>
    </Stack>
  )
}

export function downloadImage(url, filename) {
  const img = new Image();
  img.crossOrigin = 'anonymous';  // This tells the browser to request cross-origin access when trying to download the image data.
  // ref: https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image#Implementing_the_save_feature
  img.src = url;
  img.onload = () => {
    // create Canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    // create a tag
    const a = document.createElement('a');
    a.download = `${filename}`;
    a.href = canvas.toDataURL('image/*');
    a.click();
  };
}

const DownloadAllPhotosButton = ({onlyApproved, filenames}) => {
  const [loading, setLoading] = React.useState(false);

  const handleBatchDownload = async () => {
    setLoading(true);
    const zip = new JSZip(); // Crea un nuovo archivio zip
    let userId = "", eventId = "", questionId = ""

    try {
      // Itera sui file e aggiungili allo zip
      for (const filename of filenames) {
        console.log("filename", filename);

        if (!!filename) {
          const response = await axios.get(filename, {
            responseType: "blob", // Scarica i file come blob
          });
          const parts = filename.split(/[/\\]/);
          const fileName = parts[parts.length - 1];
          questionId = parts[parts.length - 2];
          eventId = parts[parts.length - 3];
          userId = parts[parts.length - 4];
          console.log("user", userId);
          zip.file(fileName, response.data); // Aggiungi il file allo zip
        }
      }

      // Genera il file zip
      const zipBlob = await zip.generateAsync({type: "blob"});
      saveAs(zipBlob, `downloaded_${onlyApproved ? "APPROVED" : "ALL"}_photos-${userId}-${eventId}-${questionId}.zip`); // Scarica il file zip
    } catch (error) {
      console.error("Errore durante il download:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      variant={onlyApproved ? "contained" : "default"}
      color="primary"
      onClick={handleBatchDownload}
      disabled={loading}
      endIcon={onlyApproved ? <Recommend/> : <AllInclusive/>}
    >
      {loading ?
        <CircularProgress size={24} color="inherit"/>
        : onlyApproved ? "Download approed pictures (Zip)" : "Download ALL pictures (Zip)"}
    </Button>
  );
};
