export const pollOptions = [
    {
        param: "radius",
        name:"Raggio",
        type: "number",
        default: 0
    },
    {
        param: "direction",
        name:"Direzione delle barre",
        type: "radio",
        default: "horizontal",
        options: [
            {
                label: "Orizzontale",
                value: "horizontal"
            },
            {
                label: "Verticale",
                value: "vertical"
            }
        ]
    },
    {
        param: "percentage",
        name:"Position of the percentage",
        type: "select",
        default: "yes",
        options: [
            {
                value: "yes",
                label: "On the results bar"
            },
            {
                value: "no",
                label: "Hide"
            },
            {
                value: "under",
                label: "Below the results bar"
            }
        ]
    },
    {
        param: "mode",
        name:"Modalità",
        type: "select",
        default: "normal",
        options: [
            {
                value: "normal",
                label: "Normale"
            },
            {
                value: "luma",
                label: "Luma"
            }
        ]
    }
]