import React, {useEffect, useState} from "react";
import {Box, Button, Grid, Paper, Stack, TextField} from "@mui/material";
import {firestore} from "../../../firebase/clientApp";
import {useParams} from "react-router-dom";
import {AddCircle, RemoveCircle} from "@mui/icons-material";
import {CustomColorPicker} from "../../shared/CustomColorPicker";
import {API_URL, baseTheme} from "../../../config";
import CustomConfirmDialog from "../../shared/CustomConfirmDialog";
import {cloneDeep, find} from "lodash";
import DialogActions from "@mui/material/DialogActions";
import axios from "axios";


export const TeamsSetup = ({event, teams, closeSettings, handleSetSaving, handleSetSuccess}) => {
    const {id} = useParams()
    const [localTeams, setLocalTeams] = useState([])

    useEffect(() => {
        if (teams && teams.docs /*&& !loading && !error*/) {
            let localTeams = teams.docs.map(t => {
                return {...t.data(), id: t.id}
            })
            setLocalTeams(localTeams)
        }
    }, [teams])

    const handleUpdateTeams = () => {
        handleSetSaving(true)
        localTeams.map(async t => {
            await firestore
                .collection('users/plesh/events/' + id + '/teams')
                .doc(t.id)
                .set(t, {merge: false})
                .then(() => {
                    handleSetSuccess(true)
                })
                .finally(() => {
                    handleSetSaving(false)
                })
        })
    }

    async function handleRemoveTeam(teamId, i) {
        handleSetSaving(true)
        /*let r = cloneDeep(localTeams)
        r?.splice(i,1)
        setLocalTeams(r)*/
        await firestore
            .collection('users/plesh/events/' + id + '/teams')
            .doc(teamId)
            .delete()
            .then(async () => {
                await axios.post(`${API_URL}/${id}/${find(teams.docs, ['id', teamId]).data()?.label}/reset`)
                handleSetSuccess(true)
            })
            .finally(() => {
                handleSetSaving(false)
            })
    }

    async function addNewTeam() {
        let r = cloneDeep(localTeams)
        r?.push({
            color: baseTheme.colors[localTeams.length - 1] || 'rgb(232, 93, 116)',
            label: ``
        })
        setLocalTeams(r)
    }

    const handleUpdateTeam = (team, i) => {
        let r = cloneDeep(localTeams)
        r?.splice(i, 1, team)
        setLocalTeams(r)
    }

    return (
        <Box sx={{width: '100%'}}>
            <Stack alignItems={'center'}>
                {(teams) &&
                    localTeams.map((team, i) =>
                        <Grid my={0.5} container alignItems={'center'}>
                            <Grid item xs={1}>
                                <CustomConfirmDialog icon={<RemoveCircle/>}
                                                     confirmText={<>Sei sicuro di voler eliminare la squadra
                                                         "{team.label}"?<br/>L'azione è permanente</>}
                                                     confirmAction={() => handleRemoveTeam(team.id, i)}
                                                     color={'text'}/>
                            </Grid>
                            <Grid item xs={11}>
                                <TeamComponent team={team} i={i} handleUpdateTeam={handleUpdateTeam}/>
                            </Grid>
                        </Grid>
                    )}
                <Button sx={{mt: 1}} endIcon={<AddCircle/>} onClick={addNewTeam} variant={'outlined'}>
                    Add team
                </Button>
            </Stack>
            <DialogActions>
                <Box sx={{mt: 2}}>
                    <Button variant={"contained"} onClick={handleUpdateTeams}>Save Squadre</Button>
                    {/*<Button onClick={()=> closeSettings()}>Close</Button>*/}
                </Box>
            </DialogActions>
        </Box>
    )
}

function TeamComponent({team, i, handleUpdateTeam}) {
    const [teamState, setTeamState] = useState(team)

    const updateColor = (color) => {
        setTeamState({
            ...teamState,
            color: color
        })
    }

    const handleSetLabel = (event) => {
        setTeamState({
            ...teamState,
            label: event.target.value
        })
    }

    useEffect(() => {
        handleUpdateTeam(teamState, i)
    }, [teamState])

    return (
        <Grid component={Paper} variant={"outlined"} p={1} container borderRadius={'12px'}>
            <Grid item xs={1}>
                <CustomColorPicker color={team.color} handleUpdateColor={updateColor}/>
            </Grid>
            <Grid item xs={11}>
                <TextField required value={team?.label} onChange={handleSetLabel} label={`Nome del team ${i + 1}`}
                           variant={"standard"} fullWidth/>
            </Grid>
        </Grid>
    );
}