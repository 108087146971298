import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {Chip, Divider, Link, Stack} from "@mui/material";
import p_plesh from '../assets/images/p_plesh.png'
import logo from '../assets/images/logo.png'
import {firebase} from "../firebase/clientApp";
import {auth} from "../firebase/clientApp";
import {useEffect} from "react";
import {Person} from "@mui/icons-material";

const _auth = true;
const pages = [
  {
    text: 'Home',
    link: '/',
    needAuth: false,
    ifAuth: true
  }
];

const ResponsiveAppBar = () => {
  const [user, setUser] = React.useState(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        console.log(user.displayName);
        console.log(user.uid);
        setUser({displayName: user.displayName, uid: user.uid, email: user.email});
      }
    })
  }, [])

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    if (anchorElUser) {
      setAnchorElUser(null);
    } else {
      setAnchorElUser(event.currentTarget);
    }
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const Pages = ({pages}) => (
    pages.map((page) => (
      <MenuItem
        key={page.text} onClick={handleCloseNavMenu}>
        <Link href={page.link} underline={'none'}>
          <Typography textAlign="center">{page.text}</Typography>
        </Link>
      </MenuItem>
    ))
  )

  const PagesButton = ({pages}) => (
    pages.map((page) => (
      <Button
        key={page.text}
        href={page.link}
        onClick={handleCloseNavMenu}
        sx={{my: 2, color: '#f1ebe9', display: 'block', textAlign: 'center'}}
      >
        {page.text}
      </Button>
    ))
  )

  const doLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
      })
      .catch((error) => {
      })
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(user?.uid || '').then(r => alert('Copiato ID: ' + user?.uid))
  }

  return (
    <AppBar position="static">
      <Box sx={{px: 2}}>
        <Toolbar disableGutters>
          <Box sx={{width: '3rem', mr: 1, display: {xs: 'none', md: 'block'}}}>
            <img src={logo} style={{width: '100%'}}/>
          </Box>
          <Box sx={{flexGrow: {xs: 0, md: 1}, display: {xs: 'flex', md: 'none'}}}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: {xs: 'block', md: 'none'},
              }}
            >
              <Pages pages={pages}/>
            </Menu>
          </Box>
          <Box sx={{display: {xs: 'flex', md: 'none'}, width: '3rem', margin: 'auto'}}>
            <img src={logo} style={{width: '100%'}}/>
          </Box>
          <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
            <PagesButton pages={pages}/>
          </Box>
          {
            _auth ? <Stack p={1} direction={'row-reverse'} alignItems="center" spacing={1}>
                <Chip label={user?.email || ''}
                      icon={<Person fontSize={'small'}/>}
                      variant="filled"
                      onClick={handleOpenUserMenu}
                      onDelete={handleOpenUserMenu}
                      deleteIcon={
                        <Box>
                          <Avatar sx={{p: 1}} alt="Plesh" src={p_plesh}/>
                          <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                          >
                            <MenuItem onClick={handleCopy}>
                              <Typography textAlign="center">Copy User ID</Typography>
                            </MenuItem>
                            <Divider/>
                            <MenuItem onClick={doLogout}>
                              <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                          </Menu>
                        </Box>}/>
              </Stack>
              : null
          }

        </Toolbar>
      </Box>
    </AppBar>
  );
};
export default ResponsiveAppBar;
