import React, {useEffect, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import {Container, TextField} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {firestore} from "../firebase/clientApp";
import {useParams} from "react-router-dom";

export default function EditEvent({open, handleSetOpen,event}){
    const {userId} = useParams()
    const [title, setTitle] = useState('')

    useEffect(()=>{
        setTitle(event.title)
    },[event])

    function handleSetTitle(event) {
        setTitle(event.target.value)
    }

    function handleEdit() {
        firestore
            .collection(`users/${userId}/events/`)
            .doc(event.id)
            .set({title:title}, {merge: true})
            .then(r => handleSetOpen())
    }

    return(
        <Dialog open={open} onClose={handleSetOpen} fullWidth>
            <DialogTitle>Edit event</DialogTitle>
            <Container>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Question"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={title}
                    onChange={handleSetTitle}
                />
                <DialogActions>
                    <Button onClick={handleSetOpen}>Cancel</Button>
                    <Button onClick={handleEdit}>Save</Button>
                </DialogActions>
            </Container>
        </Dialog>
    )
}