import React from "react";
import Box from "@mui/material/Box";
import {TextField} from "@mui/material";

export const ToolpressForm = ({conferenza, handleSetConferenza}) => {
  return(
      <Box sx={{pt:1}}>
          <TextField
              fullWidth
              variant={'standard'}
              label={'Enter the conference code'}
              required
              onChange={(e) => handleSetConferenza(e.target.value)}
              value={conferenza}
          />
      </Box>
  )
}
