import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import CustomQuillTextEditor from "./StyledQuill";

const TextForm = ({content, handleSetContent}) => {
  const [value, setValue] = useState(content);

  useEffect(() => {
    if(value) {
      handleSetContent(value);
    }
  }, [value])

  return (
    <Box sx={{mt: 3,  }}>
      <CustomQuillTextEditor theme="snow"
                value={value}
                setValue={setValue}/>
    </Box>
  )
}

export default TextForm;