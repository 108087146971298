import {Button, Stack, Typography} from "@mui/material";
import React from "react";
import CustomConfirmDialog from "../../shared/CustomConfirmDialog";
import {Download, RotateLeft} from "@mui/icons-material";
import axios from "axios";
import {API_URL} from "../../../config";
import {useParams} from "react-router-dom";
import {downloadReport} from "../../../pages/EventDetail";
import Box from "@mui/material/Box";

const ResetEvent = ({event, handleSetSaving, handleSetSuccess, closeSettings}) => {
  const { id} = useParams()

  async function handleResetEvent() {
    handleSetSaving(true)
    await axios.delete(`${API_URL}/answer/${id}`)
      .then(r => {
        handleSetSuccess(true)
      })
      .catch(e => {
        console.log("handleResetEvent > error:",e)
      })
      .finally(() => {
        handleSetSaving(false)
      })

    console.log("Reset event", id)
  }

  return (
    <Stack alignItems={'flex-start'} width={'100%'} spacing={2}>
      <Typography variant={"h6"}>Reset votes</Typography>
      <CustomConfirmDialog icon={<RotateLeft/>}
                           confirmAction={handleResetEvent}
                           label={"Reset votes"}
                           confirmText={<>Are you sure?</>}
                           variant={'outlined'}
      />
      <Typography variant={"body1"} fontWeight={'lighter'}>
        Resetting the event votes will delete the answers given to all questions.
        <br/>
        If you don't want to lose your data, remember to export your current results.
      </Typography>
      <Box pt={2} margin={'auto !important'}>
        <Button variant={'contained'} onClick={() => downloadReport(handleSetSaving, event.id)}
                endIcon={<Download fontSize={'inherit'}/>}
        >
          Download report
        </Button>
      </Box>

    </Stack>
  )
}

export default ResetEvent