export const baseTheme = {
  background: '#1C1C3D',
  colors: ["#E5634A", "#33B076", "#F00E18", "#4664EA", "#9A81C9", "#E5448A"],
  text: "#ffffff",
}

export const API_URL = "https://plesh-remote-multi-user-api-y2qbyk5t3a-ew.a.run.app"
//export const API_URL = "http://localhost:8080"

export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"
// https://api-tool-press-yn26wrnl4q-ey.a.run.app

export const BUCKET = "https://storage.googleapis.com/2ndstage-public"